<template>
  <div>
    <SimpleTable ref="table" :tableProps="tableProps" :queryFun="queryFun">
      <template slot="left-r" slot-scope="{ data }">
        <!-- <el-button
          @click="
            show = true;
            detailData = {};
          "
          class="add-btn"
          icon="el-icon-circle-plus-outline"
          >添加</el-button
        > -->
        <el-button
          @click="articleDelete(data.selectRows)"
          icon="el-icon-circle-close"
          class="dele-btn"
          >删除</el-button
        >
      </template>
      <template slot="right-r" slot-scope="{ data }"
        ><el-button
          icon="el-icon-upload2"
          class="export-btn"
          @click="exportData(data.params)"
          >导出</el-button
        ></template
      >
      <!-- <template slot="right-r" slot-scope="{ data }"
        ><el-button icon="el-icon-upload2" class="export-btn"
          >删除</el-button
        ></template
      > -->
      <div slot="action" slot-scope="{ data }">
        <el-button type="text" @click="toDetail(data.row)">查看详情</el-button>
        <el-button
          @click="articleDelete(data.row)"
          type="text"
          style="color: #f64a2d"
          >删除</el-button
        >
      </div>
      <!-- <div slot="isEnabled" slot-scope="{ data }">
        <span v-if="data.row.isEnabled" style="color: #00bc0d">是</span>
        <span v-else style="color: #f64a2d">否</span>
      </div> -->
    </SimpleTable>
  </div>
</template>

<script>
import SimpleTable from "@/components/SimpleTable";
import {
  // getExpertList,
  // deleteExpert,
  // exportDataExpert,
  //
  getNewEnergyClaimList,
  caseNewEnergyDelete,
  exportNewEnergyClaim,
} from "@/api/newEnergyclaim.js";
import { dictionaryBatch } from "@/api/policy";
export default {
  name: "newEnergyClaimList",
  components: { SimpleTable },
  data() {
    return {
      show: false,
      dictList: {
        CaseStatus: [
          { dictKey: "", dictValue: "全部" },
          { dictKey: 5, dictValue: "已接案" },
          { dictKey: 6, dictValue: "验标" },
          { dictKey: 7, dictValue: "定损" },
          { dictKey: 8, dictValue: "索赔" },
          { dictKey: 9, dictValue: "理算结案" },
        ],
      },
      detailData: {},
      tableProps: {
        fuzzyQueryKey: "search",
        currentPageKey: "current",
        currentSizeKey: "size",
        totalKey: "total",
        defaultColumnWidth: 100,
        height: 560,
        searchConfigs: [
          {
            label: "案件编号",
            type: "input",
            modelKey: "caseNo",
            width: "260px",
          },
          {
            label: "报案号",
            type: "input",
            modelKey: "realCaseNo",
            width: "260px",
          },
          {
            label: "保单号",
            type: "input",
            modelKey: "policyNo",
            width: "260px",
          },
          {
            label: "保险公司",
            type: "input",
            modelKey: "policyCompany",
            width: "260px",
          },
          {
            label: "录入时间",
            type: "daterange",
            daterangeKeys: ["createTimeStart", "createTimeEnd"],
            // daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
            modelKey: "createT",
            width: "260px",
          },
          {
            label: "车辆类型",
            type: "select",
            modelKey: "deviceType",
            option: [],
            width: "260px",
          },
          {
            label: "车牌号",
            type: "input",
            modelKey: "plate",
            width: "260px",
          },
          {
            label: "出险时间",
            type: "daterange",
            daterangeKeys: ["dangerDateStart", "dangerDateEnd"],
            // daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
            modelKey: "dangerDate",
            width: "260px",
          },
          {
            label: "出险地点",
            type: "input",
            modelKey: "dangerDetailAddress",
            width: "260px",
          },
          {
            label: "赔付总金额",
            type: "inputrange",
            isNum: true,
            inputrangeKeys: ["totalPayAmountMin", "totalPayAmountMax"],
            // daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
            modelKey: "totalPayAmount",
            width: "260px",
          },
          {
            label: "报案人",
            type: "input",
            modelKey: "reportCasePersonName",
            width: "260px",
          },
          {
            label: "报案时间",
            type: "daterange",
            daterangeKeys: ["reportCaseTimeStart", "reportCaseTimeEnd"],
            // daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
            modelKey: "reportCaseTime",
            width: "260px",
          },
          {
            label: "案件状态",
            type: "select",
            modelKey: "status",
            option: [],
            width: "260px",
          },
          {
            label: "跟进人",
            type: "input",
            modelKey: "caseFollower",
            width: "260px",
          },
          {
            label: "修改时间",
            type: "daterange",
            daterangeKeys: ["updateTimeStart", "updateTimeEnd"],
            // daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
            modelKey: "updateTime",
            width: "260px",
          },
        ],
        columnProps: [
          {
            prop: "",
            label: "序号",
            width: "55",
            type: "index",
            isShow: true,
          },
          {
            prop: "caseNo",
            label: "案件编号",
            width: "",
            type: "",
            isShow: true,
          },

          {
            prop: "realCaseNo",
            label: "报案号",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "policyNo",
            label: "保单号",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "policyCompany",
            label: "保险公司",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "deviceType",
            label: "车辆类型",
            width: "",
            isShow: true,
          },
          {
            prop: "plate",
            label: "车牌号",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "dangerDate",
            label: "出险时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "dangerDetailAddress",
            label: "出险地点",
            width: "200",
            type: "",
            isShow: true,
          },
          {
            prop: "reportCasePersonName",
            label: "报案人",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "reportCaseTime",
            label: "报案时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "statusDesc",
            label: "案件状态",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "realCompensationAmount",
            label: "赔付总金额",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "caseFollower",
            label: "跟进人",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "action",
            label: "操作",
            width: "120",
            type: "",
            slot: true,
            isShow: true,
            fixed: "right",
          },
        ],
      },
    };
  },
  created() {
    this.getDictionary();
  },
  methods: {
    async queryFun(queryParams) {
      let res = await getNewEnergyClaimList(queryParams);
      return {
        localData: res.data.records || [],
        total: res.data.total,
      };
    },
    // 跳转详情或编辑
    toDetail(item) {
      this.$router.push({
        name: "newEnergyClaimDetails",
        params: {
          id: item?.id,
          isEdit: true,
        },
      });
      // console.log(item);
      // this.detailData = item;
      // this.show = true;
    },
    exportData(queryParams) {
      exportNewEnergyClaim(queryParams);
    },
    articleDelete(items) {
      let dids = [];
      if (items instanceof Array) {
        dids = items.map((item) => item.id);
      } else {
        dids.push(items.id);
      }
      this.$confirm("删除后对应数据也将清理，是否确认删除？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        caseNewEnergyDelete(dids).then(() => {
          this.$refs.table.getData();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
      });
    },
    // 字典获取
    getDictionary() {
      let dictKeys = ["NewEnergyVehicleType"];
      dictionaryBatch({
        codes: dictKeys.join(","),
      }).then((res) => {
        if (res && res.data) {
          dictKeys.forEach((key) => {
            this.$set(this.dictList, key, res.data[key] || []);
          });
        }
        this.setSearch();
      });
    },
    setSearch() {
      this.tableProps.searchConfigs = [
        {
          label: "案件编号",
          type: "input",
          modelKey: "caseNo",
          width: "260px",
        },
        {
          label: "报案号",
          type: "input",
          modelKey: "realCaseNo",
          width: "260px",
        },
        {
          label: "保单号",
          type: "input",
          modelKey: "policyNo",
          width: "260px",
        },
        {
          label: "保险公司",
          type: "input",
          modelKey: "policyCompany",
          width: "260px",
        },
        {
          label: "录入时间",
          type: "daterange",
          daterangeKeys: ["createTimeStart", "createTimeEnd"],
          // daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
          modelKey: "createT",
          width: "260px",
        },
        {
          label: "车辆类型",
          type: "select",
          modelKey: "deviceType",
          option: this.dictList.NewEnergyVehicleType,
          width: "260px",
        },
        {
          label: "车牌号",
          type: "input",
          modelKey: "plate",
          width: "260px",
        },
        {
          label: "出险时间",
          type: "daterange",
          daterangeKeys: ["dangerDateStart", "dangerDateEnd"],
          // daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
          modelKey: "dangerDate",
          width: "260px",
        },
        {
          label: "出险地点",
          type: "input",
          modelKey: "dangerDetailAddress",
          width: "260px",
        },
        {
          label: "赔付总金额",
          type: "inputrange",
          isNum: true,
          inputrangeKeys: ["totalPayAmountMin", "totalPayAmountMax"],
          // daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
          modelKey: "totalPayAmount",
          width: "260px",
        },
        {
          label: "报案人",
          type: "input",
          modelKey: "reportCasePersonName",
          width: "260px",
        },
        {
          label: "报案时间",
          type: "daterange",
          daterangeKeys: ["reportCaseTimeStart", "reportCaseTimeEnd"],
          // daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
          modelKey: "reportCaseTime",
          width: "260px",
        },
        {
          label: "案件状态",
          type: "select",
          modelKey: "status",
          option: this.dictList.CaseStatus,
          width: "260px",
        },
        {
          label: "跟进人",
          type: "input",
          modelKey: "caseFollower",
          width: "260px",
        },
        {
          label: "修改时间",
          type: "daterange",
          daterangeKeys: ["updateTimeStart", "updateTimeEnd"],
          // daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
          modelKey: "updateTime",
          width: "260px",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.add-btn {
  background: #00bc0d;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #00bc0d;
}
.dele-btn {
  background: #c94242;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #c94242;
}
.export-btn {
  background: #4278c9;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #4278c9;
}
</style>
